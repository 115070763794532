import React, { useState, useEffect } from 'react';

import { styled } from '../../../stitches.config';

//logo
import background from '../../../assets/img/natemat/countdown-background.png';
import backgroundMobile from '../../../assets/img/natemat/countdown-background-mobile.png';
import partnerLogo from '../../../assets/img/natemat/countdown-partner.png';
import newLogo from '../../../assets/img/natemat/countdown-new-logo.png';

export default props => {

  //const startDate = Date.parse('2025-03-12T00:00:00');
  const startDate = Date.parse('2025-04-02T00:00:00');
  const endTime = Date.parse('2025-04-03T23:59:59');
  //const endTime = Date.parse('2025-03-31T11:14:59');

  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const [isFinished, setIsFinished] = useState(false);

  const calculateTime = (endTime) => {
    const time = endTime - Date.now();

    if (time <= 0) {
      setIsFinished(true);
      return;
    }

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
    setMinutes(Math.floor((time / 1000 / 60) % 60))
    setSeconds(Math.floor((time / 1000) % 60))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTime(endTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const leadingNumber = (value) => value < 10 ? "0" + value : value;

  if (Date.now() < startDate) {
    return null;
  }

  if (isFinished) {
    return null;
  }

  return (
    <StyledSection>
      <StyledWrapper>
        <img className="logo" src={newLogo} />
        <StyledCountdown>
          <div className="countdown__wrap"><span>{String(days)}</span><span>DNI</span></div>
          <div className="countdown__wrap wrap-2"><span>{String(leadingNumber(hours))}</span><span>GODZ</span></div>
          <div className="countdown__wrap"><span>{String(leadingNumber(minutes))}</span><span>MIN</span></div>
          <div className="countdown__wrap"><span>{String(leadingNumber(seconds))}</span><span>SEC</span></div>
        </StyledCountdown>
        <StyledBottom>
          <img src={partnerLogo} />
        </StyledBottom>
      </StyledWrapper>
    </StyledSection>
  );
}
const StyledSection = styled("section", {
  position: "relative",
  width: "100%",
  margin: "20px 0",

  "@bp4": {
    height: "400px",
  }
});

const StyledWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "400px",
  backgroundColor: "black",
  backgroundImage: `url("${backgroundMobile}")`,

  "@bp4": {
    backgroundSize: "1450px",
    height: "400px",
    backgroundImage: `url("${background}")`,
  },

  ".logo": {
    width: "300px",
    margin: "38px 0 30px 0",
    "@bp4": {
      width: "500px",
    },
  }
});

const StyledBottom = styled("div", {
  color: "white",
  marginTop: "20px",
  marginBottom: "25px",
  "@bp4": {
    marginTop: "40px",
    marginBottom: "20px"
  },
  "img": {
    maxWidth: "330px",
    marginTop: "20px",
    "@bp4": {
      maxWidth: "700px",
    },
  }
});

const StyledCountdown = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  marginLeft: "0px",
  "@bp4": {
    marginLeft: "-8px",
  },

  "& > div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "82px",
    "@bp4": {
      width: "125px"
    },
    "& > span": {
      color: "white",
      fontSize: "12px",
      "@bp4": {
        fontSize: "16px"
      }
    },
    "& > span:first-of-type": {
      fontWeight: "bold",
      color: "white",
      fontSize: "40px",
      lineHeight: "48px",
      "@bp4": {
        fontSize: "72px",
        lineHeight: "80px"
      }
    },
    "& > span:last-of-type": {
      fontSize: "18px",
      lineHeight: "36px",
      fontWeight: "700",
      fontStyle: "italic",

      "@bp4": {
        fontSize: "24px",
        lineHeight: "24px",
      }
    }
  },
  "& >span": {
    color: "white",
    fontSize: "40px"
  },

  "& .wrap-2": {
    "@bp4": {
      paddingRight: "10px",
    }
  }
});